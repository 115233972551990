import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"10px 5px 5px 5px"}},[(
      _vm.advanced.tab !== 0 &&
        _vm.advanced.tab !== 2 &&
        _vm.advanced.tab !== 3 &&
        _vm.advanced.tab !== 4 &&
        _vm.advanced.tab !== 7 &&
        _vm.advanced.tab !== 8 &&
        _vm.advanced.tab !== 9
    )?_c('ul',{staticClass:"canvas-menu",style:("flex-direction:row;padding:0")},[_c('draggable',{staticClass:"canvas-menu",staticStyle:{"flex-direction":"row","padding":"0"},attrs:{"options":{disabled: _vm.isMobile},"tag":"div","list":_vm.landing.menu.list}},_vm._l((JSON.parse(
                JSON.stringify(_vm.landing.menu.list)
              ).filter(function (x) { return x.active; })),function(item){return _c('li',{key:item.id,staticClass:"canvas-menu__item canvas-menu-custom",style:("margin-bottom:" + (+_vm.landing.menu.shadowSize + 10) + "px;" +
                _vm.customMenuStyles(_vm.landing.menu, item))},[(item.styles.show_name === 'top')?_c('div',{style:(("margin-bottom:10px;height:" + (item.styles.lining_height) + "px;width:" + (item.styles.lining_width) + "%;border-radius: " + (_vm.landing.menu.radius) + "px;padding:" + (item.styles.lining_padding) + "px;background-color:" + (item.styles.main_menu_lining) + ";display:flex;align-items:center;justify-content:center;flex-direction:" + (item.styles.show_icon_up ? 'column' : 'row') + ";"))},[(
                    item.styles.show_icon && item.styles.disable_icon_color
                  )?_c('img',{staticClass:"canvas-menu__icon",style:(("height:" + (item.styles.icon_size) + "px;width:" + (item.styles.icon_size) + "px;margin-right:" + (!item.styles.show_icon_up ? '15px' : '0'))),attrs:{"alt":"image","src":item.icon[_vm.landing.current_lang]},on:{"error":_vm.handleImgError}}):_vm._e(),(
                    item.styles.show_icon && !item.styles.disable_icon_color
                  )?_c('div',{staticClass:"canvas-menu__icon",style:(("margin-right:" + (!item.styles.show_icon_up ? '15px' : '0') + ";height:" + (item.styles.icon_size) + "px;width:" + (item.styles.icon_size) + "px;background-color: " + (item.styles.icon_color) + ";mask: url(" + (item.icon[_vm.landing.current_lang]) + ") no-repeat center / contain;-webkit-mask: url(" + (item.icon[_vm.landing.current_lang]) + ") no-repeat center / contain;")),on:{"error":_vm.handleImgError}}):_vm._e(),_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(item.text[_vm.landing.current_lang])+" ")])]):_vm._e(),_c('a',{staticClass:"canvas-menu__link",style:(" height:" + (item.styles.height) + "px;color: " + (item.styles.color) + ";\n                  border-radius: " + (_vm.landing.menu.radius) + "px;box-shadow:0 " + (_vm.landing.menu.shadowSize) + "px 6px " + (_vm.landing.menu.shadowColor) + ";" +
                  _vm.customMenuLinkStyles(_vm.landing.menu, item)),attrs:{"href":"#"}},[(item.styles.show_name === 'inside')?_c('div',{style:("height:" + (item.styles.lining_height) + "px;width:" + (item.styles.lining_width) + "%;padding:" + (item.styles.lining_padding) + "px;background-color:" + (item.styles.main_menu_lining) + ";display:flex;align-items:center;justify-content:center;flex-direction:" + (item.styles.show_icon_up ? 'column' : 'row') + ";"+_vm.checkRadius(item,_vm.landing.menu))},[(
                      item.styles.show_icon && item.styles.disable_icon_color
                    )?_c('img',{staticClass:"canvas-menu__icon",style:(("height:" + (item.styles.icon_size) + "px;width:" + (item.styles.icon_size) + "px;margin-right:" + (!item.styles.show_icon_up ? '15px' : '0'))),attrs:{"alt":"image","src":item.icon[_vm.landing.current_lang]},on:{"error":_vm.handleImgError}}):_vm._e(),(
                      item.styles.show_icon && !item.styles.disable_icon_color
                    )?_c('div',{staticClass:"canvas-menu__icon",style:(("margin-right:" + (!item.styles.show_icon_up ? '15px' : '0') + ";height:" + (item.styles.icon_size) + "px;width:" + (item.styles.icon_size) + "px;background-color: " + (item.styles.icon_color) + ";mask: url(" + (item.icon[_vm.landing.current_lang]) + ") no-repeat center / contain;-webkit-mask: url(" + (item.icon[_vm.landing.current_lang]) + ") no-repeat center / contain;")),on:{"error":_vm.handleImgError}}):_vm._e(),_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(item.text[_vm.landing.current_lang])+" ")])]):_vm._e()]),(item.styles.show_name === 'bottom')?_c('div',{style:(("margin-top:10px;height:" + (item.styles.lining_height) + "px;width:" + (item.styles.lining_width) + "%;margin-top:10px;border-radius: " + (_vm.landing.menu.radius) + "px;padding:" + (item.styles.lining_padding) + "px;background-color:" + (item.styles.main_menu_lining) + ";display:flex;align-items:center;justify-content:center;flex-direction:" + (item.styles.show_icon_up ? 'column' : 'row') + ";"))},[(
                    item.styles.show_icon && item.styles.disable_icon_color
                  )?_c('img',{staticClass:"canvas-menu__icon",style:(("height:" + (item.styles.icon_size) + "px;width:" + (item.styles.icon_size) + "px;margin-right:" + (!item.styles.show_icon_up ? '15px' : '0'))),attrs:{"alt":"image","src":item.icon[_vm.landing.current_lang]},on:{"error":_vm.handleImgError}}):_vm._e(),(
                    item.styles.show_icon && !item.styles.disable_icon_color
                  )?_c('div',{staticClass:"canvas-menu__icon",style:(("margin-right:" + (!item.styles.show_icon_up ? '15px' : '0') + ";height:" + (item.styles.icon_size) + "px;width:" + (item.styles.icon_size) + "px;background-color: " + (item.styles.icon_color) + ";mask: url(" + (item.icon[_vm.landing.current_lang]) + ") no-repeat center / contain;-webkit-mask: url(" + (item.icon[_vm.landing.current_lang]) + ") no-repeat center / contain;")),on:{"error":_vm.handleImgError}}):_vm._e(),_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(item.text[_vm.landing.current_lang])+" ")])]):_vm._e()])}),0)],1):_vm._e(),(_vm.advanced.tab === 3)?_c('div',{staticStyle:{"text-align":"center","margin-top":"20px"}},[_c('h5',[_vm._v("Your browser tab will looks like:")]),_c('div',{staticStyle:{"display":"flex","align-items":"center","width":"100%","height":"40px","border-top-left-radius":"5px","border-top-right-radius":"5px","background":"#eee","padding":"10px"}},[(_vm.landing.advanced.global.favicon_url)?_c('img',{staticStyle:{"height":"24px","width":"24px"},attrs:{"src":_vm.landing.advanced.global.favicon_url,"alt":"favicon"}}):_vm._e(),_c('div',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.title))])])]):_vm._e(),(_vm.advanced.tab === 4)?_c('div',{staticStyle:{"text-align":"center","margin-top":"20px"}},[_c('h5',[_vm._v("Your browser address bar will looks like:")]),_c('div',{staticStyle:{"display":"flex","align-items":"center","width":"100%","padding":"0 10px","height":"40px","border-radius":"5px","background":"#eee"}},[_c('div',[_c(VIcon,{staticStyle:{"margin-right":"5px"},attrs:{"color":"#999"}},[_vm._v("mdi-lock")]),_vm._v("https://"+_vm._s(_vm.landing.domain)+" ")],1)])]):_vm._e(),(_vm.advanced.tab === 9)?_c('div',[(_vm.page_404)?_c('div',{staticStyle:{"padding":"20px 0 0 0"},domProps:{"innerHTML":_vm._s(_vm.page_404[_vm.current_lang].content)}}):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }